// .contract-main{
//   height: 100%;
  
// }
.adm-card{
  margin-bottom: 10px;
}
.lefttitle{
  color: #838383;
  font-size: 14px;
  margin-bottom: 5px;
  border-bottom: 1px  dashed #f8f8f9;
  line-height:25px;
  // border: 1px solid;
  &:last-child{
      border: 0;
  }
  .content{
      margin-left: 10px;
      color: #000;
  }
}

.title-1{
  float: left;
}
.title-2{
  float: right;

  // margin-left: 5%;
  
  background-color: #1677ff;
  height: 20px;
  font-size: 14px;
  width: 70px;
  text-align: center;
  border-radius: 20px;
  color: white;
  font-weight:lighter;
  
}
.search-top{
  margin-bottom: 12px;
}
.title-3{
  font-size: 15px;
  font-weight: bold;
}