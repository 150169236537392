.login {
  width: 100%;
  height: 100%;
  position: absolute;

  left: 0;
  top: 0;
  background: center/cover url('~@/assets/imgs/login.png');

  .login-logo {
    width: 240px;
    height: 60px;
    display: block;
    margin: 0 auto 30px;
  }

  .login-container {
    width: 90%;
    height: 320px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
  }
}

.login-form-button-1 {
  width: 75%;
}
.login-form-button-2 {
  width: 25%;
}