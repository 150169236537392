.adm-card{
  margin-bottom: 10px;
}
.title-1{
  float: left;
}
.title-2{
  float: right;

  // margin-left: 5%;
  
  background-color: #1677ff;
  height: 20px;
  font-size: 14px;
  width: 70px;
  text-align: center;
  border-radius: 20px;
  color: white;
  font-weight:lighter;
  
}
.righttitle{
  display: block;
  width: 60px;
  text-align: right;
  float: left;
}
.jindu{
  font-size: 14px;
  color: #73B8FF;
  margin-left: 80%;
  
}
.title-3{
  font-size: 15px;
  font-weight: bold;
}